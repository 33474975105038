import { apiGet_rate } from '@/resource'
import mixin from '@/mixins'
import { endAjaxLoading } from '@/util/loading'

export default {
  mixins: [mixin],
  data() {
    return {
      loading: false,
      accountCurrency: null, // for Japanese yen which no decimal point
      minLimit: this.$config.depositDefaultAmountMin,
      maxLimit: this.$config.depositDefaultAmountMax,
    }
  },
  watch: {
    accountCurrency(newValue, oldValue) {
      if (newValue === oldValue) return

      if (newValue === 'JPY') {
        this.minLimit = this.$config.depositJPYAmountMin
      } else {
        this.minLimit = this.$config.depositDefaultAmountMin
      }
    },
  },
  methods: {
    setCurrency(currency) {
      console.log(29, currency)
      this.accountCurrency = currency
    },
    fetchRate(value) {
      apiGet_rate({
        mt4_account: value,
      })
        .then(resp => {
          if (resp.data.code == 0) this.exchangeRate = resp.data.data
          else this.errorMessage('Get error when fetching rate. Please fresh the page and try again later')
        })
        .catch(err => {
          this.errorMessage('Get error when fetching rate. Please fresh the page and try again later')
        })
    },
    depositSubmitWithRate(result, callback) {
      if (result.data.code == 0 && result.data.data) {
        callback(result.data.msg)
      } else if (result.data.code !== 562) {
        this.loading = false
        endAjaxLoading()
        this.errorMessage(this.$t('deposit.default.failed'))
      } else {
        this.loading = false
        endAjaxLoading()
      }
    },
  },
}
